/* eslint-disable max-len */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const customI18n = i18n;

const resources = {
  en: {
    translation: {
      home: 'Home',
      wishlistsList: 'My wishlists',
      login: 'Login',
      logout: 'Logout',
      profile: 'Profile',
      common: {
        noData: 'No data',
        createWishlist: 'Create wishlist',
        save: 'Save',
        cancel: 'Cancel',
        confirm: 'Confirm',
        confirmDeleteTitle: 'Confirm deletion',
      },
      loginForm: {
        title: 'Sign in',
        email: 'Email',
        password: 'Password',
        buttonText: 'Sign in',
      },
      wishlist: {
        addItemTitle: 'Add item',
        addItemButton: 'Add item to wishlist',
        updateItemTitle: 'Update item',
        updateItemButton: 'Update item to wishlist',
        viewProduct: 'View product',
        offer: 'Offer',
        alreadyOffered: 'Already offered',
        noDescription: 'No description',
        countItems: 'Total number of items: {{count}}',
        itemsOfferedProgress: '{{offered}}/{{count}} offered items',
        nameField: 'Item name',
        nameFieldError: 'Name is required',
        descriptionField: 'Description',
        priceField: 'Price',
        priceFieldRequiredError: 'Price is required',
        priceFieldMinError: 'Price must be a number greater than 0',
        priceFieldValidNumberError: 'Price must be a valid number',
        priceFieldMaxDigitsError: 'Price must have a maximum of 2 decimal places',
        imageUrlField: 'Link to the image',
        imageUrlFieldError: 'Must be a valid image URL',
        externalLinkField: 'Link to purchase the product',
        externalLinkFieldError: 'Must be a valid URL',
        itemConfirmDeleteMessage: 'Are you sure you want to delete this wishlist item? This action is irreversible.',
        publicLink: 'Public link',
        availableAmount: 'There is {{amount}}€ left to offer',
      },
      reservation: {
        reservationTitle: 'Reservation',
        name: 'Name',
        nameRequired: 'Name is required',
        email: 'Email',
        emailRequired: 'Email is required',
        emailInvalid: 'Invalid email.',
        message: 'Message',
        isPartial: 'Partial reservation',
        amount: 'Amount',
        amountRequired: 'The amount is required for a partial reservation.',
        amountMin: 'The amount must be greater than 0.',
        amountMax: 'The amount must be less than {{amount}}',
        submitSuccess: 'The reservation has been taken into account',
      },
      wishlists: {
        createTitle: 'Create wishlist',
        nameField: 'Wishlist name',
        nameFieldError: 'Name is required',
        descriptionField: 'Description',
        slugField: 'Customize URL',
        slugFieldRequired: 'The link is required',
        slugFieldError: 'Error verifying link.',
        slugUnavailable: 'This link is already taken.',
        slugFormatError:
          'The link can only contain letters, numbers, and hyphens. It must not start or end with a hyphen.',
        updateTitle: 'Edit wishlist',
        confirmDeleteMessage: 'Are you sure you want to delete this wishlist? This action is irreversible.',
      },
      homePage: {
        welcomeTitle: 'Welcome on Wishlists',
        welcomeText: 'Easily create, share and manage your wishlists for all your events.',
        plan: {
          title: 'Plan events',
          text: 'Organize your wishes for different events such as birthdays, Christmas, and more.',
        },
        custom: {
          title: 'Customized wishlist',
          text: 'Add and customize items for each wishlist according to your preferences.',
        },
        share: {
          title: 'Share easily',
          text: 'Share your wishlists with family and friends so they can see and contribute to.',
        },
      },
    },
  },
  fr: {
    translation: {
      home: 'Accueil',
      wishlistsList: 'Mes wishlists',
      login: 'Connexion',
      logout: 'Déconnexion',
      profile: 'Profil',
      common: {
        noData: 'Aucune donnée',
        createWishlist: 'Créer une wishlist',
        save: 'Enregistrer',
        cancel: 'Annuler',
        confirm: 'Confirmer',
        confirmDeleteTitle: 'Confirmer la suppression',
      },
      loginForm: {
        title: 'Se connecter',
        email: 'Email',
        password: 'Mot de passe',
        buttonText: 'Se connecter',
      },
      wishlist: {
        addItemTitle: 'Ajouter un article',
        addItemButton: 'Ajouter un article à la wishlist',
        updateItemTitle: 'Modifier un article',
        updateItemButton: 'Modifier un article à la wishlist',
        viewProduct: "Voir l'article",
        offer: 'Offrir',
        alreadyOffered: 'Déjà offert',
        noDescription: 'Aucune description',
        countItems: "Nombre total d'articles : {{count}}",
        itemsOfferedProgress: '{{offered}} / {{count}} articles réservés',
        nameField: "Nom de l'article",
        nameFieldError: 'Le nom est requis',
        descriptionField: 'Description',
        priceField: 'Prix',
        priceFieldRequiredError: 'Le prix est obligatoire',
        priceFieldMinError: 'Le prix doit être un nombre supérieur à 0',
        priceFieldValidNumberError: 'Le prix doit être un nombre valide',
        priceFieldMaxDigitsError: 'Le prix doit avoir maximum 2 décimales',
        imageUrlField: "Lien vers l'image",
        imageUrlFieldError: "Doit être un lien d'image valide",
        externalLinkField: 'Lien pour acheter le produit',
        externalLinkFieldError: 'Doit être un lien valide',
        itemConfirmDeleteMessage:
          'Êtes-vous sûr de vouloir supprimer cet article de la wishlist ? Cette action est irréversible.',
        publicLink: 'Lien public ',
        availableAmount: 'Il reste {{amount}}€ à offrir',
      },
      reservation: {
        reservationTitle: 'Réservation',
        name: 'Nom',
        nameRequired: 'Le nom est requis.',
        email: 'Email',
        emailRequired: "L'email est requis.",
        emailInvalid: 'Adresse email invalide.',
        message: 'Message',
        isPartial: 'Réservation partielle',
        amount: 'Montant',
        amountRequired: 'Le montant est requis pour une réservation partielle.',
        amountMin: 'Le montant doit être supérieur à 0.',
        amountMax: 'Le montant doit être inférieur à {{amount}}',
        submitSuccess: 'La réservation a bien été prise en compte',
      },
      wishlists: {
        createTitle: 'Créer une wishlist',
        nameField: 'Nom de la wishlist',
        nameFieldError: 'Le nom est requis',
        descriptionField: 'Description',
        slugFieldRequired: 'Le lien est requis',
        slugField: "Personnaliser l'URL",
        slugFieldError: 'Erreur lors de la vérification du lien.',
        slugUnavailable: 'Ce lien est déjà pris.',
        slugFormatError:
          'Le lien ne peut contenir que des lettres, chiffres et tirets. Il ne doit pas commencer ou terminer par un tiret.',
        updateTitle: 'Editer une wishlist',
        confirmDeleteMessage: 'Êtes-vous sûr de vouloir supprimer cette wishlist ? Cette action est irréversible.',
      },
      homePage: {
        welcomeTitle: 'Bienvenue sur Wishlists',
        welcomeText: 'Créez, partagez et gérez facilement vos listes de souhaits pour tous vos événements.',
        plan: {
          title: 'Planifiez des événements',
          text: 'Organisez vos souhaits pour différents événements tels que les anniversaires, Noël, et plus encore.',
        },
        custom: {
          title: 'Liste de souhaits personnalisée',
          text: 'Ajoutez et personnalisez des articles pour chaque wishlist en fonction de vos préférences.',
        },
        share: {
          title: 'Partagez facilement',
          text: 'Partagez vos listes avec votre famille et vos amis pour qu’ils puissent voir vos souhaits et y contribuer.',
        },
      },
    },
  },
};

customI18n.use(initReactI18next).init({
  resources,
  lng: 'fr',
  fallbackLng: 'fr',
  interpolation: {
    escapeValue: false,
  },
});

export default customI18n;
