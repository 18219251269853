import { useMutation } from 'react-query';

import wishlistApi from '../../api/wishlistApi';

export default function useCheckSlugAvailability(wishlistId: string) {
  return useMutation({
    mutationFn: async (slug: string) => {
      return await wishlistApi.get<boolean>(`/wishlists/${wishlistId}/check-slug?slug=${slug}`);
    },
  });
}
