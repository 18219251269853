import React, { useState } from 'react';
import { Card, CardContent, Box, Typography, Link, CardMedia, IconButton } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { APIWishlistItem } from '../types';
import DeleteModal from '../../ui/components/DeleteModal';
import useDeleteWishlistItem from '../hooks/useDeleteWishlistItem';
import { getDomainFromUrl } from '../../common/utils';

export default function WishlistItem({
  item,
  wishlistId,
  onUpdateClick,
}: {
  item: APIWishlistItem;
  wishlistId: string;
  onUpdateClick: (item: APIWishlistItem) => void;
}) {
  const [openDelete, setOpenDelete] = useState(false);
  const { t } = useTranslation();
  const { mutateAsync: deleteWishlist } = useDeleteWishlistItem(wishlistId);

  return (
    <Card
      sx={{
        display: 'flex',
        flex: 1,
      }}
    >
      <CardMedia
        component="img"
        src={item.imageUrl || 'https://placehold.co/150?text=Aucun+aper%C3%A7u'}
        sx={{ width: 150 }}
      />
      <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', color: item.isOffered ? 'text.secondary' : 'text.primary' }}
          >
            {item.name}
          </Typography>
          <Box>
            <Box component="span">{item.isOffered && t('wishlist.alreadyOffered')}</Box>
            <IconButton
              onClick={() => {
                onUpdateClick(item);
                const element = document.getElementById('edit-wishlist-item');
                if (element) {
                  element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
              }}
            >
              <Edit />
            </IconButton>
            <IconButton onClick={() => setOpenDelete(true)}>
              <Delete />
            </IconButton>
          </Box>
        </Box>
        <Typography variant="body2" color="textSecondary" sx={{ whiteSpace: 'pre-line' }}>
          {item.description}
        </Typography>
        <Box
          sx={{
            mt: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: item.externalLink ? 'space-between' : 'flex-end',
          }}
        >
          {item.externalLink && (
            <Box component="span">
              {`${item.price.toFixed(2).replace('.', ',')}€ sur `}
              <Link
                href={item.externalLink}
                target="_blank"
                rel="noopener"
                sx={{
                  fontWeight: 'bold',
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                {getDomainFromUrl(item.externalLink)}
              </Link>
            </Box>
          )}
        </Box>
      </CardContent>
      {openDelete && (
        <DeleteModal
          message={t('wishlist.itemConfirmDeleteMessage')}
          handleClose={() => setOpenDelete(false)}
          handleConfirmDelete={async () => {
            await deleteWishlist(item.id);
            setOpenDelete(false);
          }}
        />
      )}
    </Card>
  );
}
