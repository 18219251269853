import { useMutation } from 'react-query';

import wishlistApi from '../../api/wishlistApi';

export default function useLogout() {
  return useMutation({
    mutationFn: async () => {
      return await wishlistApi.post('/auth/logout');
    },
  });
}
