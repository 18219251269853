import React from 'react';
import { Box, CssBaseline, Container } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './i18n';
import AppBar from './ui/components/AppBar';
import AppRoutes from './AppRoutes';
import AppThemeProvider from './ui/theme';

const App = () => {
  return (
    <AppThemeProvider>
      <CssBaseline />
      <AppBar />
      <Container maxWidth="lg" sx={{ maxWidth: '1440px', height: 'calc(100% - 64px)' }}>
        <Box sx={{ padding: 2, height: '100%' }}>
          <AppRoutes />
        </Box>
      </Container>
      <ToastContainer />
    </AppThemeProvider>
  );
};

export default App;
