import React from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, InputAdornment, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ReservationFormData } from '../types';
import { APIWishlistItem } from '../../wishlist/types';
import useSubmitReservation from '../hooks/useSubmitReservation';

export function ReservationForm({ item, handleClose }: { item: APIWishlistItem; handleClose: () => void }) {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ReservationFormData>({
    defaultValues: {
      name: '',
      email: '',
      message: '',
      isPartial: item.isPartiallyOffered ? true : false,
      reservedAmount: item.availableAmount ?? item.price,
    },
  });

  const { mutateAsync: onSubmit } = useSubmitReservation(item.id);

  const { t } = useTranslation();
  const theme = useTheme();

  const isPartial = watch('isPartial');

  const handleFormSubmit: SubmitHandler<ReservationFormData> = data => {
    onSubmit({ ...data, reservedAmount: Number(`${data.reservedAmount}`.replace(',', '.')) }).then(() => {
      handleClose();
      toast(t('reservation.submitSuccess'), { type: 'success', theme: theme.palette.mode });
    });
  };

  return (
    <Dialog
      open
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      PaperProps={{
        component: 'form',
        noValidate: true,
        onSubmit: handleSubmit(handleFormSubmit),
        sx: {
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          maxWidth: 400,
          margin: '0 auto',
        },
      }}
    >
      <DialogContent>
        <Typography variant="h5" component="h2" gutterBottom textAlign="center">
          {t('reservation.reservationTitle')}
        </Typography>

        <TextField
          required
          label={t('reservation.name')}
          fullWidth
          margin="normal"
          {...register('name', { required: t('reservation.nameRequired') })}
          error={Boolean(errors.name)}
          helperText={errors.name ? errors.name.message : ''}
        />

        <TextField
          label={t('reservation.email')}
          fullWidth
          margin="normal"
          {...register('email', {
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: t('reservation.emailInvalid'),
            },
          })}
          error={Boolean(errors.email)}
          helperText={errors.email ? errors.email.message : ''}
        />

        <TextField
          label={t('reservation.message')}
          fullWidth
          margin="normal"
          multiline
          rows={4}
          {...register('message')}
        />

        <FormControlLabel
          disabled={item.isPartiallyOffered}
          control={
            <Controller
              name="isPartial"
              control={control}
              render={({ field }) => <Checkbox {...field} checked={field.value} />}
            />
          }
          label={t('reservation.isPartial')}
        />

        {isPartial && (
          <TextField
            label={t('reservation.amount')}
            type="text"
            variant="outlined"
            fullWidth
            error={!!errors.reservedAmount}
            helperText={errors.reservedAmount?.message}
            slotProps={{
              input: {
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              },
            }}
            sx={{ my: 2 }}
            required
            {...register('reservedAmount', {
              required: t('reservation.amountRequired'),
              min: {
                value: 0.01,
                message: t('reservation.amountMin'),
              },
              max: {
                value: item.availableAmount ?? item.price,
                message: t('reservation.amountMax', { amount: item.availableAmount ?? item.price }),
              },
              validate: {
                isNumber: value =>
                  !isNaN(Number(`${value}`.replace(',', '.'))) || t('wishlist.priceFieldValidNumberError'),
                isPositiveAndHasMaxTwoDecimals: value => {
                  const decimals = value?.toString().replace(',', '.').split('.')?.[1];
                  return (
                    (parseFloat(`${value}`) >= 0.01 && (!decimals || decimals.length <= 2)) ||
                    t('wishlist.priceFieldMaxDigitsError')
                  );
                },
              },
            })}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('common.cancel')}
        </Button>
        <Button type="submit" variant="contained" color="primary">
          {t('common.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
