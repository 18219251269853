import { useMutation } from 'react-query';

import wishlistApi from '../../api/wishlistApi';
import { APIWishlistListItem } from '../types';

export default function useDeleteWishlist() {
  return useMutation({
    mutationFn: async (wishlistId: string) => {
      return await wishlistApi.delete<APIWishlistListItem>(`/wishlists/${wishlistId}`);
    },
  });
}
