import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Paper, Box, Alert, Divider } from '@mui/material';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../context/AuthContext';
import GoogleLoginButton from './GoogleLoginButton';

export default function LoginForm() {
  const { login, isAuthenticated } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || '/';

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await login({ email, password });
      navigate(from);
    } catch (err) {
      if (err instanceof Error && err.message === 'Unauthorized') {
        setError('Invalid credentials');
      }
    }
  };

  return isAuthenticated() ? (
    <Navigate to={from} replace />
  ) : (
    <Container
      component="main"
      maxWidth="xs"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Paper elevation={3} style={{ padding: '20px', borderRadius: '8px' }}>
        <Typography variant="h5" align="center">
          {t('loginForm.title')}
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error}
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t('loginForm.email')}
            value={email}
            onChange={handleEmailChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t('loginForm.password')}
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />
          <Button sx={{ mt: 2 }} type="submit" fullWidth variant="contained" color="primary">
            {t('loginForm.buttonText')}
          </Button>
          <Divider flexItem sx={{ opacity: '1', mt: 2 }} />
          <Box display="flex" justifyContent="center" mt={2}>
            <GoogleLoginButton />
          </Box>
        </form>
      </Paper>
    </Container>
  );
}
