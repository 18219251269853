import { AddCircleOutline } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function CreateWishlistButton() {
  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<AddCircleOutline />}
      sx={{ mt: 3 }}
      component={Link}
      to="/wishlists/new"
    >
      {t('common.createWishlist')}
    </Button>
  );
}
