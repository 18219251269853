import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { useAtom } from 'jotai';

import { darkModeAtom } from '../../common/atoms/darkMode.atom';
import darkTheme from './dark';
import lightTheme from './light';

export default function AppThemeProvider({ children }: { children: React.ReactNode }) {
  const [darkMode] = useAtom(darkModeAtom);

  return <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>{children}</ThemeProvider>;
}
