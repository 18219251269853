import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '../../auth/context/AuthContext';

interface ProtectedRouteProps {
  role: string;
}

export default function ProtectedRoute({ children, role }: React.PropsWithChildren<ProtectedRouteProps>) {
  const { isAuthenticated, user } = useAuth();
  const location = useLocation();

  if (!isAuthenticated() || !user || !user.roles.includes(role)) {
    return <Navigate to={'/login'} state={{ from: location }} />;
  }

  return <>{children}</>;
}
