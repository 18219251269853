import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.spacing(3),
          border: '1px solid rgba(0, 0, 0, 0.2)',
        }),
      },
    },
  },
});

export default lightTheme;
