import React from 'react';
import { CircularProgress, Container } from '@mui/material';
import { useParams } from 'react-router-dom';

import useFetchWishlist from '../hooks/useFetchWishlist';
import Wishlist from './Wishlist';
import NoData from '../../common/components/NoData';

export default function WishlistPage() {
  const { wishlistId } = useParams<{ wishlistId: string }>();
  const { data, error, isLoading } = useFetchWishlist(wishlistId);

  if (isLoading) return <CircularProgress />;
  if (error) return <div>Error: {(error as Error).message}</div>;

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      {data ? <Wishlist wishlist={data} /> : <NoData />}
    </Container>
  );
}
