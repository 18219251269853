import { useMutation, useQueryClient } from 'react-query';

import wishlistApi from '../../api/wishlistApi';
import { APIWishlistListItem, WishlistFormData } from '../types';

export default function useUpdateWishlist(wishlistId: string, invalidateKey?: string[]) {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async (wishlistData: WishlistFormData) => {
      return await wishlistApi.put<APIWishlistListItem>(`/wishlists/${wishlistId}`, wishlistData);
    },
    onSuccess: () => {
      if (invalidateKey && invalidateKey.length) {
        client.invalidateQueries({ queryKey: invalidateKey });
      }
    },
  });
}
