import { Box, Typography, LinearProgress, Paper, Divider, IconButton, Link } from '@mui/material';
import { ContentCopy, Delete, Edit } from '@mui/icons-material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import WishlistItem from './WishlistItem';
import { APIWishlist, APIWishlistItem } from '../types';
import WishlistItemCreateForm from './WishlistItemCreateForm';
import WishlistItemEditForm from './WishlistItemEditForm';
import WishlistsListItemEditModal from '../../wishlists/components/WishlistsListItemEditModal';
import useDeleteWishlist from '../../wishlists/hooks/useDeleteWishlist';
import DeleteModal from '../../ui/components/DeleteModal';

export default function Wishlist({ wishlist }: { wishlist: APIWishlist }) {
  const [editableItem, setEditableItem] = useState<APIWishlistItem | null>(null);
  const [copied, setCopied] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const { mutateAsync: deleteWishlist } = useDeleteWishlist();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const totalItems = wishlist.items.length;
  const reservedItems = wishlist.items.filter(item => item.isOffered).length;
  const progress = (reservedItems / totalItems) * 100;

  const domain = process.env.REACT_APP_DOMAIN ?? 'https://wishlist.lolitaclement.fr';
  const link = `${domain}/w/${wishlist.slug}`;

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <Paper sx={{ padding: 4, borderRadius: 4, boxShadow: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
          {wishlist.name}
        </Typography>
        <Box>
          <IconButton onClick={() => setOpenEdit(true)}>
            <Edit />
          </IconButton>
          <IconButton onClick={() => setOpenDelete(true)}>
            <Delete />
          </IconButton>
        </Box>
      </Box>

      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
        <Typography>
          {`${t('wishlist.publicLink')}: `}
          <Link target="_blank" href={link}>
            {link}
          </Link>
        </Typography>
        <IconButton onClick={async () => await copyToClipboard()} color={copied ? 'success' : 'default'}>
          <ContentCopy />
        </IconButton>
      </Box>

      <Typography
        sx={!wishlist.description ? { fontStyle: 'italic', mb: 2 } : { whiteSpace: 'pre-line', mb: 2 }}
        gutterBottom
      >
        {wishlist.description || t('wishlist.noDescription')}
      </Typography>

      <Typography variant="subtitle1">{t('wishlist.countItems', { count: totalItems })}</Typography>

      {wishlist.items.length > 0 && (
        <>
          <Box sx={{ width: '100%', my: 2 }}>
            <LinearProgress
              variant="determinate"
              value={progress}
              color="primary"
              sx={{
                height: 10,
                borderRadius: 5,
                '& .MuiLinearProgress-bar': {
                  borderRadius: 5,
                  backgroundColor: progress === 100 ? 'success.main' : 'primary',
                },
              }}
            />
            <Typography variant="body2">
              {t('wishlist.itemsOfferedProgress', { offered: reservedItems, count: totalItems })}{' '}
            </Typography>
          </Box>

          {wishlist.items.map(item => (
            <Box key={item.id} sx={{ mb: 2 }}>
              <WishlistItem
                wishlistId={wishlist.id}
                item={item}
                onUpdateClick={(item: APIWishlistItem) => setEditableItem(item)}
              />
            </Box>
          ))}
        </>
      )}
      <Divider sx={{ my: 2 }} />
      {editableItem ? (
        <WishlistItemEditForm
          wishlistId={wishlist.id}
          item={editableItem}
          onUpdate={() => setEditableItem(null)}
          onCancel={() => setEditableItem(null)}
        />
      ) : (
        <WishlistItemCreateForm wishlistId={wishlist.id} />
      )}
      {openEdit && <WishlistsListItemEditModal handleClose={() => setOpenEdit(false)} wishlist={wishlist} />}
      {openDelete && (
        <DeleteModal
          message={t('wishlists.confirmDeleteMessage')}
          handleClose={() => setOpenDelete(false)}
          handleConfirmDelete={async () => {
            await deleteWishlist(wishlist.id);
            setOpenDelete(false);
            navigate('/wishlists');
          }}
        />
      )}
    </Paper>
  );
}
