import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.spacing(3),
          border: '1px solid rgba(255, 255, 255, 0.2)',
        }),
      },
    },
  },
});

export default darkTheme;
