import { atom } from 'jotai';

export const createLocalStorageAtom = <T>(key: string, initialValue: T) => {
  const getInitialValue = (): T => {
    if (typeof window !== 'undefined') {
      const saved = localStorage.getItem(key);
      return saved !== null ? JSON.parse(saved) : initialValue;
    }
    return initialValue;
  };

  const localStorageAtom = atom(getInitialValue());

  const localStorageStorageAtom = atom(
    get => get(localStorageAtom),
    (get, set, newValue: T) => {
      set(localStorageAtom, newValue);
      if (typeof window !== 'undefined') {
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    },
  );

  return localStorageStorageAtom;
};
