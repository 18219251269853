import { useMutation, useQueryClient } from 'react-query';

import wishlistApi from '../../api/wishlistApi';
import { APIReservation, ReservationFormData } from '../types';

export default function useSubmitReservation(wishlistItemId: string) {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (reservationData: ReservationFormData) => {
      return await wishlistApi.post<APIReservation>(`/reservations/${wishlistItemId}`, reservationData);
    },
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['reservations', wishlistItemId] });
      client.invalidateQueries({ queryKey: ['wishlist'] });
    },
  });
}
