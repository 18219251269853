import { useMutation } from 'react-query';

import wishlistApi from '../../api/wishlistApi';
import { APIWishlistListItem, WishlistCreateFormData } from '../types';

export default function useAddWishlist() {
  return useMutation({
    mutationFn: async (wishlistData: WishlistCreateFormData) => {
      return await wishlistApi.post<APIWishlistListItem>('/wishlists', wishlistData);
    },
  });
}
