import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

import { APIErrorResponse, APISuccessResponse } from './types';

class Fetcher {
  private client: AxiosInstance;

  constructor(baseURL: string) {
    this.client = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });

    // Intercepteur de réponse pour gérer les erreurs
    this.client.interceptors.response.use(undefined, this.handleError);
  }

  private formatResponse<T>(response: AxiosResponse<APISuccessResponse<T>>): T {
    return response.data.data;
  }

  private handleError(error: AxiosError<APIErrorResponse>) {
    // Vous pouvez personnaliser la gestion des erreurs ici
    const message = error.response?.data.message || error.message;
    // Vous pouvez également ajouter une journalisation ici
    console.error('API Error:', message);

    return Promise.reject(new Error(message));
  }

  public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T | undefined> {
    const response = await this.client.get<APISuccessResponse<T>>(url, config);
    return this.formatResponse<T>(response);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T | undefined> {
    const response = await this.client.post<APISuccessResponse<T>>(url, data, config);
    return this.formatResponse<T>(response);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async put<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T | undefined> {
    const response = await this.client.put<APISuccessResponse<T>>(url, data, config);
    return this.formatResponse<T>(response);
  }

  public async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T | undefined> {
    const response = await this.client.delete<APISuccessResponse<T>>(url, config);
    return this.formatResponse<T>(response);
  }
}

export default Fetcher;
