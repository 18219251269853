import React from 'react';
import { CredentialResponse, GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';

const GoogleLoginButton: React.FC = () => {
  const { i18n } = useTranslation();
  const { onGoogleLoginSuccess } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || '/';

  const onSuccess = async (response: CredentialResponse) => {
    await onGoogleLoginSuccess(response);
    navigate(from);
  };

  return process.env.REACT_APP_GOOGLE_CLIENT_ID ? (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <GoogleLogin locale={i18n.language} onSuccess={onSuccess} useOneTap />
    </GoogleOAuthProvider>
  ) : null;
};

export default GoogleLoginButton;
