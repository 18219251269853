import React from 'react';
import { Route, Routes } from 'react-router-dom';

import WishlistsList from './wishlists/components/WishlistsList';
import LoginForm from './auth/components/LoginForm';
import Home from './home/components/Home';
import WishlistCreateForm from './wishlists/components/WishlistsCreateForm';
import ProtectedRoute from './common/components/ProtectedRoute';
import WishlistPage from './wishlist/components/WishlistPage';
import PublicWishlistPage from './public-wishlist/components/PublicWishlistPage';

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/w/:slug" element={<PublicWishlistPage />} />
      <Route
        path="/wishlists/new"
        element={
          <ProtectedRoute role="user">
            <WishlistCreateForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="/wishlists/:wishlistId"
        element={
          <ProtectedRoute role="user">
            <WishlistPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/wishlists"
        element={
          <ProtectedRoute role="user">
            <WishlistsList />
          </ProtectedRoute>
        }
      />
      <Route path="/login" element={<LoginForm />} />
    </Routes>
  );
}
