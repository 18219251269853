import { useQuery } from 'react-query';

import wishlistApi from '../../api/wishlistApi';
import { APIWishlistListItem } from '../types';

export default function useFetchWishlists() {
  return useQuery({
    queryFn: async () => {
      return await wishlistApi.get<APIWishlistListItem[]>('/wishlists');
    },
    queryKey: ['wishlists'],
  });
}
