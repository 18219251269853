import { useQuery } from 'react-query';

import wishlistApi from '../../api/wishlistApi';
import { APIWishlist } from '../types';

export default function useFetchWishlist(wishlistId?: string) {
  return useQuery({
    queryFn: async () => {
      return await wishlistApi.get<APIWishlist>(`/wishlists/${wishlistId}`);
    },
    queryKey: ['wishlist', wishlistId],
    enabled: Boolean(wishlistId),
  });
}
