import React from 'react';
import { CircularProgress, Container } from '@mui/material';
import { useParams } from 'react-router-dom';

import NoData from '../../common/components/NoData';
import useFetchWishlistBySlug from '../hooks/useFetchWishlistBySlug';
import PublicWishlist from './PublicWishlist';

export default function PublicWishlistPage() {
  const { slug } = useParams<{ slug: string }>();
  const { data, error, isLoading } = useFetchWishlistBySlug(slug);

  if (isLoading) return <CircularProgress />;
  if (error) return <div>Error: {(error as Error).message}</div>;

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      {data ? <PublicWishlist wishlist={data} /> : <NoData />}
    </Container>
  );
}
