import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function NoData() {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography>{t('common.noData')}</Typography>
    </Box>
  );
}
