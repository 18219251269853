import {
  AppBar as AppBarMui,
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Logout from '@mui/icons-material/Logout';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';

import { useAuth } from '../../auth/context/AuthContext';
import { darkModeAtom } from '../../common/atoms/darkMode.atom';

const menuConfig = [
  { label: 'home', path: '/', protected: false, role: 'user' },
  { label: 'wishlistsList', path: '/wishlists', protected: true, role: 'user' },
];

export default function AppBar() {
  const [darkMode, setDarkMode] = useAtom(darkModeAtom);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { t } = useTranslation();
  const { user, isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <AppBarMui position="sticky">
      <Container maxWidth="lg" sx={{ maxWidth: '1440px' }}>
        <Toolbar>
          {isMobile ? (
            <>
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
              <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
                <List
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                >
                  <Box>
                    {menuConfig
                      .filter(item => !item.protected || (isAuthenticated() && user && user.roles.includes(item.role)))
                      .map((item, index) => (
                        <ListItemButton
                          component={NavLink}
                          key={index}
                          onClick={handleDrawerClose}
                          to={item.path}
                          sx={{
                            '&.active': {
                              border: '1px solid currentColor',
                            },
                            margin: 1,
                          }}
                        >
                          <ListItemText primary={t(item.label)} />
                        </ListItemButton>
                      ))}
                  </Box>
                  <Box>
                    <Divider />
                    <Box sx={{ padding: 2 }}>
                      {isAuthenticated() && user ? (
                        <>
                          <ListItemButton>
                            <ListItemIcon>
                              {user.profilePicture ? (
                                <Avatar src={user.profilePicture} sx={{ width: 24, height: 24 }} />
                              ) : (
                                <AccountCircle />
                              )}
                            </ListItemIcon>
                            <ListItemText>
                              <Typography>{t('profile')}</Typography>
                            </ListItemText>
                          </ListItemButton>
                          <ListItemButton onClick={logout}>
                            <ListItemIcon>
                              <Logout />
                            </ListItemIcon>
                            <ListItemText>{t('logout')}</ListItemText>
                          </ListItemButton>
                        </>
                      ) : (
                        <Button fullWidth onClick={() => navigate('/login')}>
                          {t('login')}
                        </Button>
                      )}
                    </Box>
                  </Box>
                </List>
              </Drawer>
            </>
          ) : (
            <Box sx={{ display: 'flex', flexGrow: 1, gap: 2 }}>
              {menuConfig
                .filter(item => !item.protected || (isAuthenticated() && user && user.roles.includes(item.role)))
                .map((item, index) => (
                  <Button
                    key={index}
                    color="inherit"
                    onClick={handleDrawerClose}
                    to={item.path}
                    component={NavLink}
                    sx={{
                      '&.active': {
                        border: '1px solid currentColor',
                      },
                    }}
                  >
                    {t(item.label)}
                  </Button>
                ))}
            </Box>
          )}

          {!isMobile ? (
            <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: 2 }}>
              <IconButton color="inherit" onClick={toggleDarkMode}>
                {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>

              {isAuthenticated() && user ? (
                <>
                  <Tooltip title={t('profile')}>
                    <IconButton color="inherit">
                      {user.profilePicture ? (
                        <Avatar src={user.profilePicture} sx={{ width: 24, height: 24 }} />
                      ) : (
                        <AccountCircle />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('logout')}>
                    <IconButton color="inherit" onClick={logout}>
                      <Logout />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <Button color="inherit" onClick={() => navigate('/login')}>
                  {t('login')}
                </Button>
              )}
            </Box>
          ) : (
            <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: 2 }}>
              <IconButton color="inherit" onClick={toggleDarkMode}>
                {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBarMui>
  );
}
