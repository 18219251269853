import React from 'react';
import { Box, TextField, Button, Typography, InputAdornment } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { APIWishlistItem, WishlistItemFormData } from '../types';
import useUpdateWishlistItem from '../hooks/useUpdateWishlistItem';

export default function WishlistItemEditForm({
  wishlistId,
  item,
  onUpdate,
  onCancel,
}: {
  wishlistId: string;
  item: APIWishlistItem;
  onCancel?: () => void;
  onUpdate?: () => void;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<WishlistItemFormData>({
    defaultValues: {
      name: item.name,
      description: item.description,
      imageUrl: item.imageUrl,
      externalLink: item.externalLink,
      price: item.price,
    },
  });

  const { t } = useTranslation();
  const { mutateAsync: onSubmit } = useUpdateWishlistItem({ wishlistId, wishlistItemId: item.id });

  const handleFormSubmit: SubmitHandler<WishlistItemFormData> = data => {
    onSubmit({ ...data, price: Number(`${data.price}`.replace(',', '.')) }).then(() => {
      onUpdate?.();
    });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(handleFormSubmit)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        maxWidth: 400,
        margin: '0 auto',
      }}
      id="edit-wishlist-item"
    >
      <Typography variant="h5" component="h2" gutterBottom textAlign="center">
        {t('wishlist.updateItemTitle')}
      </Typography>

      <TextField
        label={t('wishlist.nameField')}
        variant="outlined"
        required
        error={!!errors.name}
        helperText={errors.name ? errors.name.message : ''}
        {...register('name', { required: t('wishlist.nameFieldError') })}
      />

      <TextField
        label={t('wishlist.externalLinkField')}
        variant="outlined"
        error={!!errors.externalLink}
        helperText={errors.externalLink ? errors.externalLink.message : ''}
        {...register('externalLink', {
          pattern: {
            value: /^https?:\/\/.+$/,
            message: t('wishlist.externalLinkFieldError'),
          },
        })}
      />

      <TextField
        label={t('wishlist.descriptionField')}
        variant="outlined"
        multiline
        rows={4}
        {...register('description')}
      />

      <TextField
        label={t('wishlist.priceField')}
        type="text"
        variant="outlined"
        fullWidth
        error={!!errors.price}
        helperText={errors.price?.message}
        slotProps={{
          input: {
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          },
        }}
        {...register('price', {
          required: t('wishlist.priceFieldRequiredError'),
          min: {
            value: 0.01,
            message: t('wishlist.priceFieldMinError'),
          },
          validate: {
            isNumber: value => !isNaN(Number(`${value}`.replace(',', '.'))) || t('wishlist.priceFieldValidNumberError'),
            isPositiveAndHasMaxTwoDecimals: value => {
              const decimals = value.toString().replace(',', '.').split('.')[1];
              return (
                (parseFloat(`${value}`) >= 0.01 && (!decimals || decimals.length <= 2)) ||
                t('wishlist.priceFieldMaxDigitsError')
              );
            },
          },
        })}
      />

      <TextField
        label={t('wishlist.imageUrlField')}
        variant="outlined"
        error={!!errors.imageUrl}
        helperText={errors.imageUrl ? errors.imageUrl.message : ''}
        {...register('imageUrl', {
          pattern: {
            value: /^(https?:\/\/.*\.(?:png|jpg|jpeg|gif|svg|webp))$/,
            message: t('wishlist.imageUrlFieldError'),
          },
        })}
      />

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={() => onCancel?.()} color="primary">
          {t('common.cancel')}
        </Button>
        <Button type="submit" variant="contained" color="primary">
          {t('wishlist.updateItemButton')}
        </Button>
      </Box>
    </Box>
  );
}
