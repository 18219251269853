import { useMutation, useQueryClient } from 'react-query';

import wishlistApi from '../../api/wishlistApi';
import { APIWishlistItem, WishlistItemFormData } from '../types';

export default function useUpdateWishlistItem({
  wishlistId,
  wishlistItemId,
}: {
  wishlistId: string;
  wishlistItemId: string;
}) {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (wishlistItemData: WishlistItemFormData) => {
      return await wishlistApi.put<APIWishlistItem>(
        `/wishlists/${wishlistId}/items/${wishlistItemId}`,
        wishlistItemData,
      );
    },
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['wishlist', wishlistId] });
    },
  });
}
