import { useMutation } from 'react-query';

import wishlistApi from '../../api/wishlistApi';
import { User } from '../types';

export default function useSignInWithGoogle() {
  return useMutation({
    mutationFn: async (googleToken?: string) => {
      if (!googleToken) {
        return;
      }
      return await wishlistApi.post<{ user?: User; expiresAt?: string }>('/auth/google/login', {
        token: googleToken,
      });
    },
  });
}
