import React, { useState } from 'react';
import { Card, CardContent, Box, Typography, Link, Button, CardMedia } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getDomainFromUrl } from '../../common/utils';
import { APIWishlistItem } from '../../wishlist/types';
import { ReservationForm } from '../../reservation/components/ReservationForm';

export default function PublicWishlistItem({ item }: { item: APIWishlistItem }) {
  const { t } = useTranslation();
  const [openReservation, setOpenReservation] = useState(false);

  return (
    <>
      <Card
        sx={theme => ({
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          flex: 1,
          position: 'relative',
          ...(item.isOffered
            ? {
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background: theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.6)' : 'rgba(255, 255, 255, 0.6)',
                },
              }
            : {}),
        })}
      >
        <CardMedia
          component="img"
          src={item.imageUrl || 'https://placehold.co/150?text=Aucun+aper%C3%A7u'}
          sx={{
            width: { xs: '100%', sm: 150 },
            height: { xs: 200, sm: 'auto' },
          }}
        />
        <CardContent
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            p: { xs: 2, sm: 3 },
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
            <Typography variant="h6" color="text.primary" sx={{ fontWeight: 'bold' }}>
              {item.name}
            </Typography>
            {item.isOffered ? (
              <Box component="span" sx={{ fontWeight: 'bold' }}>
                {t('wishlist.alreadyOffered')}
              </Box>
            ) : item.isPartiallyOffered && item.availableAmount ? (
              <Box component="span" sx={{ fontWeight: 'bold', mt: { xs: 1, sm: 0 } }}>
                {t('wishlist.availableAmount', { amount: item.availableAmount.toFixed(2).replace('.', ',') })}
              </Box>
            ) : null}
          </Box>
          <Typography variant="body2" color="textSecondary" sx={{ whiteSpace: 'pre-line', mt: { xs: 2, sm: 1 } }}>
            {item.description}
          </Typography>
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: item.externalLink ? 'space-between' : 'flex-end',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: { xs: 1, sm: 0 },
            }}
          >
            {item.externalLink && (
              <Box component="span">
                {`${item.price.toFixed(2).replace('.', ',')}€ sur `}
                <Link
                  href={item.externalLink}
                  target="_blank"
                  rel="noopener"
                  sx={{
                    fontWeight: 'bold',
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                >
                  {getDomainFromUrl(item.externalLink)}
                </Link>
              </Box>
            )}
            {!item.isOffered && (
              <Button
                onClick={() => setOpenReservation(true)}
                variant="contained"
                sx={{
                  width: { xs: '100%', sm: 'auto' },
                }}
              >
                {t('wishlist.offer')}
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
      {openReservation && <ReservationForm handleClose={() => setOpenReservation(false)} item={item} />}
    </>
  );
}
