import React from 'react';
import { CircularProgress, Container, Stack } from '@mui/material';

import useFetchWishlists from '../hooks/useFetchWishlists';
import { APIWishlistListItem } from '../types';
import WishlistsListItem from './WishlistsListItem';
import NoData from '../../common/components/NoData';
import CreateWishlistButton from '../../common/components/CreateWishlistButton';

export default function WishlistsList() {
  const { data, error, isLoading, refetch } = useFetchWishlists();

  if (isLoading) return <CircularProgress />;
  if (error) return <div>Error: {(error as Error).message}</div>;

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Stack direction={{ xs: 'column' }} spacing={2} justifyContent="center" alignItems="stretch">
        {data?.length ? (
          data.map((wishlist: APIWishlistListItem) => (
            <WishlistsListItem key={wishlist.id} wishlist={wishlist} onUpdate={refetch} onDelete={refetch} />
          ))
        ) : (
          <NoData />
        )}
        <CreateWishlistButton />
      </Stack>
    </Container>
  );
}
