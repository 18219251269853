import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function DeleteModal({
  handleClose,
  handleConfirmDelete,
  message,
}: {
  handleClose: () => void;
  handleConfirmDelete: () => void;
  message: string;
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle id="confirmation-dialog-title">{t('common.confirmDeleteTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('common.cancel')}
        </Button>
        <Button onClick={handleConfirmDelete} color="error" autoFocus>
          {t('common.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
