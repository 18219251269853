import { Event, Favorite, Share } from '@mui/icons-material';
import { Container, Box, Typography, Paper, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CreateWishlistButton from '../../common/components/CreateWishlistButton';

export default function Home() {
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Box textAlign="center" mb={4}>
        <Typography variant="h2" component="h1" gutterBottom>
          {t('homePage.welcomeTitle')}
        </Typography>
        <Typography variant="h5" color="text.secondary">
          {t('homePage.welcomeText')}
        </Typography>
        <CreateWishlistButton />
      </Box>

      <Stack direction={{ xs: 'column', md: 'row' }} spacing={4} justifyContent="center" alignItems="stretch">
        <Paper elevation={3} sx={{ p: 3, textAlign: 'center', flex: 1 }}>
          <Event color="primary" fontSize="large" />
          <Typography variant="h6" gutterBottom>
            {t('homePage.plan.title')}
          </Typography>
          <Typography color="text.secondary">{t('homePage.plan.text')}</Typography>
        </Paper>

        <Paper elevation={3} sx={{ p: 3, textAlign: 'center', flex: 1 }}>
          <Favorite color="secondary" fontSize="large" />
          <Typography variant="h6" gutterBottom>
            {t('homePage.custom.title')}
          </Typography>
          <Typography color="text.secondary">{t('homePage.custom.text')}</Typography>
        </Paper>

        <Paper elevation={3} sx={{ p: 3, textAlign: 'center', flex: 1 }}>
          <Share color="action" fontSize="large" />
          <Typography variant="h6" gutterBottom>
            {t('homePage.share.title')}
          </Typography>
          <Typography color="text.secondary">{t('homePage.share.text')}</Typography>
        </Paper>
      </Stack>
    </Container>
  );
}
