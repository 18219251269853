import { Dialog, TextField, Button, DialogContent, DialogActions, Typography, InputAdornment } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Cancel, CheckCircle } from '@mui/icons-material';

import { APIWishlistListItem, WishlistFormData } from '../types';
import useUpdateWishlist from '../hooks/useUpdateWishlist';
import useCheckSlugAvailability from '../hooks/useCheckSlugAvailability';

export default function WishlistsListItemEditModal({
  wishlist,
  handleClose,
  handleEdit,
}: {
  wishlist: APIWishlistListItem;
  handleClose: () => void;
  handleEdit?: () => void;
}) {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<WishlistFormData>({
    defaultValues: { name: wishlist.name, slug: wishlist.slug, description: wishlist.description },
  });
  const { t } = useTranslation();
  const { mutateAsync: checkSlug } = useCheckSlugAvailability(wishlist.id);
  const { mutateAsync: onSubmit } = useUpdateWishlist(wishlist.id, ['wishlist', wishlist.id]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSlugAvailable, setIsSlugAvailable] = useState(true);

  const slug = watch('slug');

  useEffect(() => {
    if (!slug) {
      clearErrors('slug');
      return;
    }

    const slugRegex = /^[a-zA-Z0-9]+(?:[-_][a-zA-Z0-9]+)*$/;
    if (!slugRegex.test(slug)) {
      setError('slug', {
        type: 'manual',
        message: t('wishlists.slugFormatError'),
      });
      return;
    }

    const debounce = setTimeout(async () => {
      setIsLoading(true);
      try {
        // Appel à l'endpoint pour vérifier la disponibilité du slug
        const isSlugAvailable = await checkSlug(slug);
        setIsSlugAvailable(isSlugAvailable ?? false);
        if (!isSlugAvailable) {
          setError('slug', {
            type: 'manual',
            message: t('wishlists.slugUnavailable'),
          });
        } else {
          clearErrors('slug');
        }
      } catch (err) {
        setError('slug', {
          type: 'manual',
          message: t('wishlists.slugFieldError'),
        });
      } finally {
        setIsLoading(false);
      }
    }, 500); // Délai de debounce de 500 ms

    return () => clearTimeout(debounce);
  }, [slug, setError, clearErrors, checkSlug, t]);

  const handleFormSubmit: SubmitHandler<WishlistFormData> = data => {
    onSubmit(data).then(() => {
      handleClose();
      handleEdit?.();
    });
  };

  return (
    <Dialog
      open
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit(handleFormSubmit),
      }}
    >
      <DialogContent>
        <Typography variant="h5" component="h2" gutterBottom textAlign="center">
          {t('wishlists.updateTitle')}
        </Typography>
        <TextField
          label={t('wishlists.nameField')}
          fullWidth
          margin="normal"
          {...register('name', { required: t('wishlists.nameFieldError') })}
          error={Boolean(errors.name)}
          required
          helperText={errors.name ? errors.name.message : ''}
        />
        <TextField
          label={t('wishlists.slugField')}
          fullWidth
          margin="normal"
          required
          {...register('slug', { required: t('wishlists.slugFieldRequired') })}
          error={Boolean(errors.slug)}
          helperText={errors.slug ? errors.slug.message : ''}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  {`${process.env.REACT_APP_DOMAIN ?? 'https://wishlist.lolitaclement.fr'}/w/`}
                </InputAdornment>
              ),
              endAdornment: isLoading ? (
                <span>⏳</span>
              ) : isSlugAvailable ? (
                <CheckCircle color="success" />
              ) : (
                <Cancel color="error" />
              ),
            },
          }}
        />
        <TextField
          label={t('wishlists.descriptionField')}
          multiline
          fullWidth
          margin="normal"
          {...register('description')}
          error={Boolean(errors.description)}
          helperText={errors.description ? errors.description.message : ''}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('common.cancel')}
        </Button>
        <Button disabled={!isValid} type="submit" variant="contained" color="primary">
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
