import { useMutation, useQueryClient } from 'react-query';

import wishlistApi from '../../api/wishlistApi';
import { APIWishlistItem } from '../types';

export default function useDeleteWishlistItem(wishlistId: string) {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (wishlistItemId: string) => {
      return await wishlistApi.delete<APIWishlistItem>(`/wishlists/${wishlistId}/items/${wishlistItemId}`);
    },
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['wishlist', wishlistId] });
    },
  });
}
