import React, { useEffect } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { APIWishlist } from '../../wishlist/types';
import PublicWishlistItem from './PublicWishlistItem';

export default function PublicWishlist({ wishlist }: { wishlist: APIWishlist }) {
  const { t } = useTranslation();

  useEffect(() => {
    if (wishlist.name) {
      document.title = `Wishlist - ${wishlist.name}`;
    }

    return () => {
      document.title = 'Wishlist';
    };
  }, [wishlist.name]);

  return (
    <Paper sx={{ padding: 4, borderRadius: 4, boxShadow: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
          {wishlist.name}
        </Typography>
      </Box>

      <Typography
        sx={!wishlist.description ? { fontStyle: 'italic', mb: 2 } : { whiteSpace: 'pre-line', mb: 2 }}
        gutterBottom
      >
        {wishlist.description || t('wishlist.noDescription')}
      </Typography>

      {wishlist.items.map(item => (
        <Box key={item.id} sx={{ mb: 2 }}>
          <PublicWishlistItem item={item} />
        </Box>
      ))}
    </Paper>
  );
}
