import { useQuery } from 'react-query';

import wishlistApi from '../../api/wishlistApi';
import { APIWishlist } from '../../wishlist/types';

export default function useFetchWishlistBySlug(slug?: string) {
  return useQuery({
    queryFn: async () => {
      return await wishlistApi.get<APIWishlist>(`/wishlists/by-slug/${slug}`);
    },
    queryKey: ['wishlist', slug],
    enabled: Boolean(slug),
  });
}
