import { useMutation } from 'react-query';

import wishlistApi from '../../api/wishlistApi';
import { User } from '../types';

export default function useSignIn() {
  return useMutation({
    mutationFn: async ({ email, password }: { email: string; password: string }) => {
      return await wishlistApi.post<{ user?: User; expiresAt?: string }>('/auth/login', {
        email,
        password,
      });
    },
  });
}
